/*--------------------------------

nucleo icon font
Generated using nucleoapp.com

-------------------------------- */

@font-face {
  font-family: 'nucleo';
  src: url('../fonts/nucleo.eot');
  src: url('../fonts/nucleo.eot') format('embedded-opentype'),
       url('../fonts/nucleo.woff2') format('woff2'),
       url('../fonts/nucleo.woff') format('woff'),
       url('../fonts/nucleo.ttf') format('truetype'),
       url('../fonts/nucleo.svg') format('svg');
}

/* base class */
.icon {
  display: inline-block;
  font: normal normal normal 1em/1 'nucleo';
  color: inherit;
  flex-shrink: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* size examples - relative units */
.icon-sm {
  font-size: 0.8em;
}

.icon-lg {
  font-size: 1.2em;
}

/* size examples - absolute units */
.icon-16 {
  font-size: 16px;
}

.icon-32 {
  font-size: 32px;
}

/* rotate the icon infinitely */
.icon-is-spinning {
  animation: icon-spin 1s infinite linear;
}

@keyframes icon-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* transform */
.icon-rotate-90 {
  transform: rotate(90deg);
}

.icon-rotate-180 {
  transform: rotate(180deg);
}

.icon-rotate-270 {
  transform: rotate(270deg);
}

.icon-flip-y {
  transform: scaleY(-1);
}

.icon-flip-x {
  transform: scaleX(-1);
}

/* icons */
.cafe-admin::before {
  content: "\ea02";
}

.cafe-add-user::before {
  content: "\ea03";
}

.cafe-version::before {
  content: "\ea05";
}

.cafe-ice::before {
  content: "\ea06";
}

.cafe-options::before {
  content: "\ea07";
}

.cafe-accounts::before {
  content: "\ea08";
}

.cafe-dashboard::before {
  content: "\ea09";
}

.cafe-profile::before {
  content: "\ea0a";
}

.cafe-host::before {
  content: "\ea0b";
}

.cafe-media::before {
  content: "\ea0c";
}

.cafe-bullet::before {
  content: "\ea0d";
}

.cafe-align-center::before {
  content: "\ea0e";
}

.cafe-monitor::before {
  content: "\ea0f";
}

.cafe-incidents::before {
  content: "\ea10";
}

.cafe-unavailability::before {
  content: "\ea11";
}

.cafe-outages::before {
  content: "\ea12";
}

.cafe-sla::before {
  content: "\ea13";
}

.cafe-creation::before {
  content: "\ea14";
}

.cafe-status::before {
  content: "\ea15";
}

.cafe-refresh::before {
  content: "\ea16";
}

.cafe-first::before {
  content: "\ea17";
}

.cafe-forward::before {
  content: "\ea18";
}

.cafe-back::before {
  content: "\ea19";
}

.cafe-last::before {
  content: "\ea1a";
}

.cafe-ice-host::before {
  content: "\ea1b";
}

.cafe-ice-turn::before {
  content: "\ea1c";
}

.cafe-ice-stun::before {
  content: "\ea1d";
}

.cafe-proxy::before {
  content: "\ea1e";
}

.cafe-nat::before {
  content: "\ea1f";
}

.cafe-protocols::before {
  content: "\ea20";
}

.cafe-login::before {
  content: "\ea21";
}

.cafe-password::before {
  content: "\ea22";
}

.cafe-add::before {
  content: "\ea23";
}

.cafe-selected::before {
  content: "\ea24";
}

.cafe-star::before {
  content: "\ea25";
}

.cafe-change::before {
  content: "\ea26";
}

.cafe-warning::before {
  content: "\ea27";
}

.cafe-cafe::before {
  content: "\ea28";
}

.cafe-monitoring::before {
  content: "\ea29";
}

.cafe-stun::before {
  content: "\ea2a";
}

.cafe-turn::before {
  content: "\ea2b";
}

.cafe-telemetry::before {
  content: "\ea2c";
}

.cafe-measure::before {
  content: "\ea2d";
}

.cafe-monitoring-1::before {
  content: "\ea2e";
}

.cafe-features::before {
  content: "\ea2f";
}

.cafe-configuration::before {
  content: "\ea30";
}

.cafe-security-1::before {
  content: "\ea31";
}

.cafe-key::before {
  content: "\ea32";
}

.cafe-credit-locked::before {
  content: "\ea33";
}

.cafe-configuration-1::before {
  content: "\ea34";
}

.cafe-configuration-2::before {
  content: "\ea35";
}

.cafe-notmonitor::before {
  content: "\ea36";
}

.cafe-dots::before {
  content: "\ea37";
}

.cafe-meeting::before {
  content: "\ea38";
}

.cafe-sig::before {
  content: "\ea3b";
}

.cafe-relay::before {
  content: "\ea3c";
}

.cafe-media-1::before {
  content: "\ea3d";
}

.cafe-wrench::before {
  content: "\ea3e";
}

.cafe-chemistry::before {
  content: "\ea3f";
}

.cafe-list::before {
  content: "\ea40";
}

.cafe-computer::before {
  content: "\ea41";
}

.cafe-button-pause::before {
  content: "\ea42";
}

.cafe-doc-folder::before {
  content: "\ea43";
}

.cafe-server::before {
  content: "\ea44";
}

.cafe-area::before {
  content: "\ea45";
}

.cafe-environment::before {
  content: "\ea46";
}

.cafe-group::before {
  content: "\ea47";
}

.cafe-others::before {
  content: "\ea48";
}

.cafe-staging::before {
  content: "\ea49";
}

.cafe-production::before {
  content: "\ea4a";
}

.cafe-dashboard-1::before {
  content: "\ea4b";
}

.cafe-series::before {
  content: "\ea4c";
}

.cafe-trend-up::before {
  content: "\ea4d";
}

.cafe-warning-1::before {
  content: "\ea4e";
}

.cafe-error::before {
  content: "\ea4f";
}

.cafe-exclamation::before {
  content: "\ea50";
}

.cafe-warning-sign::before {
  content: "\ea51";
}

.cafe-delete::before {
  content: "\ea52";
}

.cafe-edit::before {
  content: "\ea53";
}

.cafe-play::before {
  content: "\ea54";
}
