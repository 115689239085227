:root {
    --c-grey-100: #f4f6f8;
    --c-grey-200: #e3e3e3;
    --c-grey-300: #b2b2b2;
    --c-grey-400: #7b7b7b;
    --c-grey-500: #3d3d3d;

    --c-blue-500: #688afd;
}

.timeline {
    width: 85%;
    max-width: 700px;
    margin-left: 110px;
    margin-top: 12px;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    padding: 16px 0 8px 3px;
    border-left: 2px solid var(--c-grey-200);
}

.timeline-item {
    display: flex;
    gap: 8px;

    & + * {
        margin-top: 24px;
    }

    & + .extra-space {
        margin-top: 48px;
    }
}

.new-comment {
    width: 100%;

    input {
        border: 1px solid var(--c-grey-200);
        border-radius: 6px;
        height: 48px;
        padding: 0 16px;
        width: 100%;

        &::placeholder {
            color: var(--c-grey-300);
        }

        &:focus {
            border-color: var(--c-grey-300);
            outline: 0;
        }
    }
}

.before-timeline {
    margin-left: -110px;
    margin-top: 7px;
    width: 100px;
    max-width: 100px;
    color: var(--c-grey-400);
}

.timeline-item-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    width: 40px;
    height: 40px;
    margin-left: -22px;

    svg {
        width: 20px;
        height: 20px;
    }

    &.faded-icon {
        background-color: var(--c-grey-100);
        color: #037BFF;
    }

    &.filled-icon {
        background-color: var(--c-grey-100);
        color: #037BFF;
    }
}

.timeline-item-description {
    display: flex;
    padding-top: 6px;
    color: var(--c-grey-400);
    margin-bottom: 0;
    padding-bottom: 0;
    height: 30px;

    img {
        flex-shrink: 0;
    }

    a {
        color: var(--c-grey-500);
        font-weight: 500;
        text-decoration: none;

        &:hover,
        &:focus {
            outline: 0;
        }
    }
}

.avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    aspect-ratio: 1 / 1;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    background-color: var(--c-grey-100);
    color: #037BFF;

    &.small {
        width: 28px;
        height: 28px;
    }

    img {
        object-fit: cover;
    }
}

.comment {
    margin-top: 12px;
    color: var(--c-grey-500);
    border: 1px solid var(--c-grey-200);
    box-shadow: 0 4px 4px 0 var(--c-grey-100);
    border-radius: 6px;
    padding: 16px;
    font-size: 1rem;
}

.button {
    border: 0;
    padding: 0;
    display: inline-flex;
    vertical-align: middle;
    margin-right: 4px;
    margin-top: 12px;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    height: 32px;
    padding: 0 8px;
    background-color: var(--c-grey-100);
    flex-shrink: 0;
    cursor: pointer;
    border-radius: 99em;

    &:hover {
        background-color: var(--c-grey-200);
    }

    &.square {
        border-radius: 50%;
        color: var(--c-grey-400);
        width: 32px;
        height: 32px;
        padding: 0;

        svg {
            width: 24px;
            height: 24px;
        }

        &:hover {
            background-color: var(--c-grey-200);
            color: var(--c-grey-500);
        }
    }
}

.show-replies {
    color: var(--c-grey-300);
    background-color: transparent;
    border: 0;
    padding: 0;
    margin-top: 16px;
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 1rem;
    cursor: pointer;

    svg {
        flex-shrink: 0;
        width: 24px;
        height: 24px;
    }

    &:hover,
    &:focus {
        color: var(--c-grey-500);
    }
}

.avatar-list {
    display: flex;
    align-items: center;

    & > * {
        position: relative;
        box-shadow: 0 0 0 2px #fff;
        margin-right: -8px;
    }
}
