video {
    width: 100%;
    min-height: 240px;
}

.visible {
    display: inherit;
}

.hidden {
    display: none;
}

.videoBackground {
    background: #222;
}

.videoArea {
    background: #222;
    margin: 0;
    width: 1px;
    height: 1px;
}

.video {
    width: 1px !important;
    height: 1px !important;
}

.graph {
    background-color: #fff;
    border-radius: 2px;
}

.card-dashed {
    border: 1px dashed;
}

.outages-graph {
    pointer-events: none;
    visibility: hidden;
    z-index: -1;
    background-color: #fff;
    border-radius: 2px;
}

.chart-container {
    height: 300px;
}

.chart-container-status {
    height: 100px;
}
