.service-health {
    background-color: #11cdef !important;
}

.service-tests {
    background-color: #ffd600 !important;
}

.service-usage {
    background-color: #f15ed7 !important;
}

.dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}


