.parameters-select {
    font-size: 0.75rem;
}

.icon-save {
    width: 18px;
    height: 18px;
    font-size: 16px;
    margin-right: 4px;
}
