.radio-duration {
    padding-left: 0 !important;
}

.radio-position {
    margin-left: 20px;
}

.label-duration {
    margin-bottom: 0;
}

.form-duration {
    margin-top: 0;
}

.modal-title {
    font-size: 20px;
    font-weight: 600;
    color: #344675;
}

.input-color, .input-color:active, input-color:focus {
    color: #344675 !important;
}

.text-active {
    color: #344675 !important;
}

.form-check {
    margin-top: 0;
}

.modal-align-right {
    justify-content: end !important;
}


