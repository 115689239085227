.form-description {
    font-size: 0.65rem;
    color: #57595B;
}

.card-separator {
    border-color: #2b3553;
}

.selectColor {
    font-size: 12px;
    border-color: #2b3553;
    border-radius: 0.4285rem;
    background-color: transparent;
}

.myClass {
    width: 120px;
    font-size: 11px;
    margin: 0 8px 0 0;
}

div[class$="-control"] {
    height: 26px;
    min-height: 26px;
}

div[class$="-placeholder"] {
    height: 26px;
    min-height: 26px;
}

div[class$="-singleValue"] {
}

div[class$="-IndicatorsContainer"] {
    height: 26px;
    min-height: 26px;
    display: none;
}

div[class$="-menu"] {
    color: #000;
}

.changeButton {
    width: 18px;
    height: 18px;
}
