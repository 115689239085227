@media (min-width: 992px) {
    .sidebar-mini .main-panel > .content-selector {
        padding-left: 130px;
    }
}

.main-panel > .content-selector {
    padding: 0 30px 0px 280px;
}

.main-panel > .content-selector-with {
    padding: 80px 30px 0px 280px;
}

.main-panel > .admin-content {
    padding: 100px 30px 30px 30px;
    min-height: 0 !important;
}

body::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

body::-webkit-scrollbar-track {
    background-color: #28293d;
    border-radius: 100px;
}

body::-webkit-scrollbar-thumb {
    background-color: #525f7f;
    border-radius: 100px;
}
