.dashboard-sub-wrapper {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 0 1px rgb(16 22 26 / 10%), 0 0 0 rgb(16 22 26 / 0%), 0 1px 1px rgb(16 22 26 / 20%);
    margin: 0 auto;
    width: 100%;
}

.dashboard-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

svg {
    border-radius: 5px;
}
