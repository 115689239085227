.switchTo {
    font-size: 0.7rem;
    font-style: italic;
    margin-right: 4px;
}

.photoName {
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 0px 9px;
    background: #1e8af8;
    color: #fff;
}

.photoName:hover {
    cursor: pointer;
}

.iconMenu {
    padding-top: 8px;
    padding-right: 18px;
}

.brandIcon {
    font-size: large !important;
}

.navbar-nav li {
    padding: 0 !important;
}

.host-switch {
    padding: 4px 0;
    font-size: 0.9rem;
    font-weight: 300;
    height: 24px;
    width: 28px;
}

.host-switch-image {
    height: 24px;
    width: 28px;
}
