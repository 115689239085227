#map {
    height: 350px;
    width: 100%;
    display: flex;
}

.bullet {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: inline-block;
}

.bullet-blue {
    background-color: #6b7db3;
}

.bullet-cyan {
    background-color: #e6ecff;
}

.bullet-violet {
    background-color: #9999ff;
}

.bullet-yellow {
    background-color: #ffd699;
}

.bullet-green {
    background-color: #6bb39a;
}

.bullet-orange {
    background-color: #e4ad5d;
}

.icon-selected {
    height: 18px;
    width: 18px;
}

.icon-monitoring {
    font-size: 23px;
    height: 20px;
}

.host a {
    color: #fff;
    text-decoration: none;
}

.host a:hover {
    text-decoration: underline;
}

.action-button {
    height: 24px;
    width: 28px;
    padding: 4px 0;
    font-size: 0.9rem;
    font-weight: 300;
}

.action-button-image {
    height: 24px;
    width: 28px;
    margin-top: 4px;
}
