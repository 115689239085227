.tableButtons {
    width: 44px;
    text-align: inherit;
    height: 26px;
}

.noMarginBottom {
    margin-bottom: 0 !important;
    line-height: 11px !important;
}

.hostSelector {
}

.otherPanel {
    margin-top: 150px;
}

.content-top {
    position: relative;
    overflow: hidden;
}

.tooltip-icon {
    background-color: #1d7cda;
    padding: 0px 5px;
    border-radius: 50%;
    cursor: pointer;
    font-size: 10px;
    position: absolute;
}

.tooltip-icon:hover {
    background-color: #00bbff;
}

.tooltip-badge {
    cursor: pointer;
}
