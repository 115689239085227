.menu-separator {
    background: #0a58ca52;
    height: 40px;
    line-height: 40px;
    padding-left: 20px;
}

.sidebar-mini .menu-separator {
    transform: translate3d(-25px, 0, 0);
    display: none;
}

.sidebar .nav li > a {
    margin-top: 0 !important;
}

.sidebar-mini .sidebar-wrapper {
    overflow: hidden;
}

.sidebar {
    position: absolute !important;
    height: 1200px !important;
}

.sidebar-wrapper {
    height: 100% !important;
}

.sidebar-host {
    margin: 20px 0 0 8px;
}

.sidebar-picker {
    text-align: left;
    width: 212px;
    display: flex;
}

.icon-small {
    width: 18px;
    height: 18px;
    font-size: 16px;
    margin-left: -24px;
    margin-right: 4px;
}

.sidebar-new-host {
    text-align: right;
    margin-right: 10px;
    font-size: 12px;
}

.sidebar-add-button {
    margin: 0;
    padding: 0;
    font-size: 12px;
}

.sidebar-modal > .modal-content {
    background: #fefefe;
}

.sidebar-modal-text {
    color: #525f7f;
}

.sidebar-modal-select {
    font-size: 0.75rem;
}

.sidebar-modal-input {
    color: #525f7f;
}

.sidebar-modal-input:focus {
    color: #525f7f !important;
}
