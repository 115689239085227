.tableButtons {
    width: 44px;
    text-align: inherit;
    height: 26px;
}

.icon-selected {
    width: 16px;
    height: 16px;
    padding-top: 2px;
}
